import { NgClass } from '@angular/common';
import { Component, Inject, inject } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { LottieComponent } from 'ngx-lottie';
import { appUrlsConstantsInjectionToken } from 'src/app/data/injection-tokens/app-urls-constants.injection-token';
import { BaseComponent } from 'src/app/presentation/base/base.component';
import { TieringProgressBarComponent } from '../tiering-progress-bar/tiering-progress-bar.component';
import { OtherChallengesComponent } from './other-challenges/other-challenges.component';
import { TieringWidgetPresenter } from './presenter/tiering-widget.presenter';
import { TieringWidgetSideEffect } from './presenter/tiering-widget.sideEffects';
import { TieringWidgetViewEvents } from './presenter/tiering-widget.viewEvents';
import { TieringWidgetViewState } from './presenter/tiering-widget.viewState';

@Component({
  selector: 'app-tiering-widget',
  standalone: true,
  imports: [
    TranslateModule,
    TieringProgressBarComponent,
    OtherChallengesComponent,
    NgClass,
    LottieComponent,
  ],
  templateUrl: './tiering-widget.component.html',
  styleUrls: ['./tiering-widget.component.scss'],
  providers: [TieringWidgetPresenter],
})
export class TieringWidgetComponent extends BaseComponent<
  TieringWidgetPresenter,
  TieringWidgetViewState,
  TieringWidgetViewEvents,
  TieringWidgetSideEffect
> {
  public presenter: TieringWidgetPresenter = inject(TieringWidgetPresenter);

  public assetsPath = 'assets/img/';

  constructor(
    private _router: Router,
    @Inject(appUrlsConstantsInjectionToken) private _appURLs: { [url: string]: string },
  ) {
    super();
  }

  public onInit(): void {
    this.presenter.emitViewEvent({
      type: 'Init',
    });
  }

  onSideEffect(sideEffect: TieringWidgetSideEffect): void {
    switch (sideEffect.type) {
      case 'NavigateToDynamicIncentives': {
        this._router.navigate([this._appURLs.INCENTIVE_URL]);
        break;
      }
      case 'NavigateToLoyaltyProgram': {
        this._router.navigate([this._appURLs.LOYALTY_URL]);
        break;
      }
    }
  }

  clickGoToLoyaltyProgram(): void {
    this.presenter.emitViewEvent({
      type: 'ClickGoToLoyaltyProgram',
    });
  }

  clickGoToDynamicIncentives(): void {
    this.presenter.emitViewEvent({
      type: 'ClickGoToDynamicIncentives',
    });
  }
}
