<div class="test-products-header">
  <!-- start -->
  <div class="test-products-header__tier-container">
    <p class="caption2--medium">
      {{
        trans('PRODUCTS_PAGE.CAROUSEL_NAMES.LOCKED_TESTABLE_PRODUCTS_CAROUSEL_SUBTITLE', {
          tier: localizedValue(viewState.merchantTierData?.label)
        })
      }}
    </p>
    <div class="test-products-header__tier">
      <img
        src="assets/img/test-tier-rectangle.svg"
        alt="testing locked products background"
        class="test-products-header__tier_bg"
        [ngClass]="{ 'flip-bg': isRTL }"
      />
      <img
        [src]="viewState.merchantTierData?.icon"
        alt="merchant tier"
        class="test-products-header__tier_icon"
      />
      <p class="test-products-header__tier_title body1--bold">
        {{ trans('PRODUCTS_PAGE.CAROUSEL_NAMES.LOCKED_TESTABLE_PRODUCTS_CAROUSEL_TITLE') }}
      </p>
    </div>
  </div>

  <!-- end -->
  <p class="test-products-header_view-all body2--bold" (click)="goToProductsPage()">
    {{ trans('HEADER.CATEGORIES_MEGA_MENU.VIEW_ALL') }}
  </p>
</div>
