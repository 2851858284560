import { NgFor, NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { IvyCarouselModule } from 'angular-responsive-carousel';
import { ResponsiveService } from 'src/app/presentation/shared/services/responsive.service';
import { UserService } from 'src/app/presentation/shared/services/user.service';
import { LoaderComponent } from '../../../shared/components/loader/loader.component';
import { HoverStyleDirective } from '../../../shared/directives/hover-style.directive';

@Component({
  selector: 'app-top-banner',
  templateUrl: './top-banner.component.html',
  styleUrls: ['./top-banner.component.scss'],
  standalone: true,
  imports: [NgIf, IvyCarouselModule, NgFor, HoverStyleDirective, LoaderComponent],
})
export class TopBannerComponent implements OnInit {
  public announcementsList: any[];

  public showNavigationArrows: boolean;

  public isMobile: boolean;

  public state = 'idle';

  constructor(private userService: UserService, private responsiveService: ResponsiveService) {
    this.announcementsList = [];
    this.showNavigationArrows = true;
  }

  ngOnInit(): void {
    this.getIsMobile();
    this.getAnnouncement();
    const screenSize = this.responsiveService.getScreenWidth();
    if (screenSize === 'xl') {
      this.showNavigationArrows = true;
    } else if (screenSize === 'lg') {
      this.showNavigationArrows = false;
    } else if (screenSize === 'md') {
      this.showNavigationArrows = false;
    } else if (screenSize === 'sm') {
      this.showNavigationArrows = false;
    } else {
      this.showNavigationArrows = false;
    }
  }

  getAnnouncement(): void {
    this.state = 'loading';
    this.userService.getAnnouncement().subscribe({
      next: (res: any) => {
        this.announcementsList = res.data.filter(
          (announcement: any) => announcement.isMobile === this.isMobile,
        );
      },
      complete: () => {
        this.state = 'idle';
      },
    });
  }

  getIsMobile(): void {
    this.responsiveService.getMobileStatus().subscribe({
      next: (isMobile) => {
        this.isMobile = isMobile;
      },
    });
  }
}
