// locked-testable-products-header Presenter

import { Injectable } from '@angular/core';
import { combineLatest } from 'rxjs';
import { GetLoyaltyProgressUseCase } from 'src/app/core/usecases/loyalty-program/get-progress.usecase';
import { GetLoyaltyTiersDataUseCase } from 'src/app/core/usecases/loyalty-program/get-tiers-data.usecase';
import { BasePresenter } from 'src/app/presentation/base/base.presenter';
import { LockedTestableProductsHeaderSideEffect } from './locked-testable-products-header.side-effects';
import { LockedTestableProductsHeaderViewEvent } from './locked-testable-products-header.view-events';
import { LockedTestableProductsHeaderViewState } from './locked-testable-products-header.view-state';

@Injectable({ providedIn: 'root' })
export class LockedTestableProductsHeaderPresenter extends BasePresenter<
  LockedTestableProductsHeaderViewState,
  LockedTestableProductsHeaderViewEvent,
  LockedTestableProductsHeaderSideEffect
> {
  constructor(
    private _getLoyaltyTiersDataUseCase: GetLoyaltyTiersDataUseCase,
    private _getLoyaltyProgressUseCase: GetLoyaltyProgressUseCase,
  ) {
    super();
  }

  protected defaultViewState(): LockedTestableProductsHeaderViewState {
    return {
      merchantTierData: {
        icon: '',
        label: { ar: '', en: '' },
      },
    };
  }

  protected onViewEvent(event: LockedTestableProductsHeaderViewEvent): void {
    switch (event.type) {
      case 'Init': {
        const loyaltyTiersData$ = this._getLoyaltyTiersDataUseCase.execute();

        const loyaltyProgress$ = this._getLoyaltyProgressUseCase.execute();

        this.add(
          combineLatest([loyaltyTiersData$, loyaltyProgress$]).subscribe(
            ([loyaltyTiersData, loyaltyProgress]) => {
              const tierData = loyaltyTiersData.filter(
                (tier) => tier.index === loyaltyProgress.currentLoyaltyTierIndex,
              )[0];

              this.updateViewState({
                merchantTierData: {
                  icon: tierData.icon,
                  label: tierData.displayName,
                },
              });
            },
          ),
        );
      }
    }
  }
}
