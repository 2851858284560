/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-underscore-dangle */

import { Component, Inject, inject, Input, OnInit } from '@angular/core';
import { RouterLink } from '@angular/router';
import { DeviceDetectorService, DeviceInfo } from 'ngx-device-detector';
import { LogMixpanelEventUseCase } from 'src/app/core/usecases/analytics/log-mixpanel-event.usecase';
import { appUrlsConstantsInjectionToken } from 'src/app/data/injection-tokens/app-urls-constants.injection-token';
import { SORT_BY_INTRODUCED_AT } from 'src/app/presentation/shared/constants/category-products';
import { CategoryInterface } from 'src/app/presentation/shared/interfaces/product.interafce';

@Component({
  selector: 'app-category-card',
  templateUrl: './category-card.component.html',
  styleUrls: ['./category-card.component.scss'],
  standalone: true,
  imports: [RouterLink],
})
export class CategoryCardComponent implements OnInit {
  @Input() category: CategoryInterface;

  @Input() areIconsLoaded: boolean;

  @Input() cardLocation = 'products-v2';

  navigationRoute: [string, string];

  navigationQueryParams: {
    category: string;
    currentPage: number;
    items: number;
    sorting: string;
  };

  deviceInfo: DeviceInfo;

  private _logMixpanelEventUseCase: LogMixpanelEventUseCase = inject(LogMixpanelEventUseCase);

  constructor(
    private deviceService: DeviceDetectorService,
    @Inject(appUrlsConstantsInjectionToken) private _appURLs: { [url: string]: string },
  ) {
    this.detectDevice();
  }

  ngOnInit(): void {
    // eslint-disable-next-line no-underscore-dangle
    this.navigationRoute = [this._appURLs.CATEGORY_PRODUCTS_URL, this.category._id];
    this.navigationQueryParams = {
      category: this.category.text,
      currentPage: 1,
      items: 12,
      sorting: SORT_BY_INTRODUCED_AT,
    };
  }

  detectDevice(): void {
    this.deviceInfo = this.deviceService.getDeviceInfo();
  }

  goToCategory(): void {
    if (this.cardLocation === 'products-v2') {
      this._logMixpanelEventUseCase.execute({
        eventName: 'Go_to_category',
        payload: {
          currentPage: this.cardLocation,
          'Category Name': this.category,
          device: this.deviceInfo!.device,
          deviceType: this.deviceInfo!.deviceType,
          orientation: this.deviceInfo!.orientation,
          operatingSystem: this.deviceInfo!.os_version,
          userAgent: this.deviceInfo!.userAgent,
        },
      });
    } else if (this.cardLocation === 'no-search-results') {
      this._logMixpanelEventUseCase.execute({
        eventName: 'No_results_page_click_category',
        payload: {
          currentPage: this.cardLocation,
          Language: 'ar',
          'Interface Version': 'v2',
          // eslint-disable-next-line no-underscore-dangle
          'Category Id': this.category._id,
          'Category Name': this.category.name,
        },
      });
    }
  }
}
