import { Component, Input, OnInit } from '@angular/core';
import { RouterLink } from '@angular/router';
import { SORT_BY_INTRODUCED_AT } from 'src/app/presentation/shared/constants/category-products';
import { SubBannerDataInterface } from 'src/app/presentation/shared/interfaces/product.interafce';
import { HoverStyleDirective } from '../../../shared/directives/hover-style.directive';

@Component({
  selector: 'app-sub-banner',
  templateUrl: './sub-banner.component.html',
  styleUrls: ['./sub-banner.component.scss'],
  standalone: true,
  imports: [HoverStyleDirective, RouterLink],
})
export class SubBannerComponent implements OnInit {
  @Input() bannerData: SubBannerDataInterface;

  public queryParams: any;

  ngOnInit(): void {
    this.queryParams = {
      currentPage: 1,
      items: 12,
      sorting: SORT_BY_INTRODUCED_AT,
    };
  }
}
