import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { navigateTo } from '@presentation/shared/router';
import { BaseComponent } from 'app/presentation/base/base.component';
import { LockedTestableProductsHeaderPresenter } from './presentation/locked-testable-products-header.presenter';
import { LockedTestableProductsHeaderSideEffect } from './presentation/locked-testable-products-header.side-effects';
import { LockedTestableProductsHeaderViewEvent } from './presentation/locked-testable-products-header.view-events';
import { LockedTestableProductsHeaderViewState } from './presentation/locked-testable-products-header.view-state';

@Component({
  selector: 'app-locked-testable-products-header',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './locked-testable-products-header.component.html',
  styleUrls: ['./locked-testable-products-header.component.scss'],
  providers: [LockedTestableProductsHeaderPresenter],
})
export class LockedTestableProductsHeaderComponent extends BaseComponent<
  LockedTestableProductsHeaderPresenter,
  LockedTestableProductsHeaderViewState,
  LockedTestableProductsHeaderViewEvent,
  LockedTestableProductsHeaderSideEffect
> {
  public presenter: LockedTestableProductsHeaderPresenter = inject(
    LockedTestableProductsHeaderPresenter,
  );

  public onInit(): void {
    this.presenter.emitViewEvent({
      type: 'Init',
    });
  }

  public goToProductsPage(): void {
    const queryParams = {
      currentPage: 1,
      items: 12,
      sorting: 'introducedAt',
      lockedTestableProducts: true,
    };

    navigateTo('/products/category', queryParams);
  }
}
