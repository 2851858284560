import { Component, inject, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { finalize, take } from 'rxjs/operators';

import { NgClass, NgFor, NgIf, TitleCasePipe } from '@angular/common';
import { country } from '@features/country/data';
import { LockedTestableProductFilterModel } from '@features/locked-testable-products/data';
import { LockedTestableProductsUseCaseResolver } from '@features/locked-testable-products/domain';
import { user } from '@features/user/data';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { IvyCarouselModule } from 'angular-responsive-carousel';
import { combineLatest } from 'rxjs';
import { LogMixpanelEventUseCase } from 'src/app/core/usecases/analytics/log-mixpanel-event.usecase';
import { GetCommercialCategoriesUseCase } from 'src/app/core/usecases/get-commercial-categories-usecase';
import { GetFeatureAttributeUsecase } from 'src/app/core/usecases/get-feature-attribute.usecase';
import { GetFeatureFlagUsecase } from 'src/app/core/usecases/get-feature-flag.usecase';
import { GetUpsellableProductsUseCase } from 'src/app/core/usecases/products/get-upsellable-products.usecase';
import {
  ALL_SALE_PRODUCTS_CAROUSEL,
  DISCOUNTED_PRODUCTS_CAROUSEL_TITLE,
  FEATURE_FLAGS,
  LOCKED_PRODUCTS_CAROUSEL_NAME,
  LOCKED_PRODUCTS_CATEGORY,
  LOCKED_TESTABLE_PRODUCTS_CATEGORY,
  UPSELLABLE_PRODUCTS_CATEGORY,
} from 'src/app/presentation/shared/constants';
import { CarouselInterface } from 'src/app/presentation/shared/interfaces/product.interafce';
import { VariantGroup } from 'src/app/presentation/shared/interfaces/variant';
import { ProductService } from 'src/app/presentation/shared/services/product.service';
import { ResponsiveService } from 'src/app/presentation/shared/services/responsive.service';
import { featureAttributeAssign } from 'src/app/presentation/shared/utilities/feature-attribute-assign.utility';
import { ProductCardComponent } from '../product-card/product-card.component';
import { LockedTestableProductsHeaderComponent } from './locked-testable-products-header/locked-testable-products-header.component';

@Component({
  selector: 'app-products-carousel',
  templateUrl: './products-carousel.component.html',
  styleUrls: ['./products-carousel.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    NgClass,
    IvyCarouselModule,
    NgFor,
    ProductCardComponent,
    TranslateModule,
    TitleCasePipe,
    LockedTestableProductsHeaderComponent,
  ],
})
export class ProductsCarouselComponent implements OnInit {
  @Input() carouselData: CarouselInterface;

  @Input() isCatalogDataLoaded = false;

  public shouldShowStockDistribution = false;

  public variantGroups: VariantGroup[];

  public categoryName: string;

  public categoryId: string;

  public sorting: string;

  public title: string;

  public upSellableVariant: string;

  public randomize: boolean;

  public featuredGroupId: number;

  public tooltipVisible: boolean;

  public itemsPerSlide: number;

  public showNavigationArrows: boolean;

  public loading = true;

  public products: any[] = [];

  public arrowsOutside: boolean;

  public pageNumber = 1;

  lockedOnly = false;

  public onlyGroupsOnSale = false;

  public onlyDiscountedAsSecondInPlacement = false;

  public discountedAsSecondProductFlag = false;

  public assetsPath = 'assets/';

  public upsellableProductsFlag = false;

  private _logMixpanelEventUseCase: LogMixpanelEventUseCase = inject(LogMixpanelEventUseCase);

  public shouldShowQuantityDiscount = false;

  public shouldShowQuantityDiscountCard = false;

  public shouldShowMarketplaceV3_2 = false;

  public merchantLockedOnly = false;

  constructor(
    public responsiveService: ResponsiveService,
    public productService: ProductService,
    private router: Router,
    private getFeatureFlagUseCase: GetFeatureFlagUsecase,
    private _getUpsellableProductsUseCase: GetUpsellableProductsUseCase,
    private _translateService: TranslateService,
    private _getCommercialCategoriesUseCase: GetCommercialCategoriesUseCase,
    private _getFeatureAttributeUseCase: GetFeatureAttributeUsecase,
  ) {
    this.products = [];
  }

  ngOnInit(): void {
    combineLatest([
      this.getFeatureFlagUseCase.execute(FEATURE_FLAGS.UPSELLABLE_PRODUCTS_FLAG),
      this.getFeatureFlagUseCase.execute(FEATURE_FLAGS.DISCOUNTED_AS_SECOND_PRODUCT),
    ]).subscribe({
      next: ([upsellableProductsFlag, discountedAsSecondProductFlag]) => {
        this.upsellableProductsFlag = upsellableProductsFlag;
        this.discountedAsSecondProductFlag = discountedAsSecondProductFlag;
        this.setupInputs();
      },
    });
    this._getFeatureAttributeUseCase.execute(FEATURE_FLAGS.QUANTITY_DISCOUNT).subscribe({
      next: (attribute) => {
        this.shouldShowQuantityDiscount = featureAttributeAssign(attribute, user.id, country.code);
      },
    });
    this._getFeatureAttributeUseCase.execute(FEATURE_FLAGS.WEB_DISCOUNT_PRODUCT_CARD).subscribe({
      next: (attribute) => {
        this.shouldShowQuantityDiscountCard = featureAttributeAssign(
          attribute,
          user.id,
          country.code,
        );
      },
    });

    this.isStockDistributionEnabled();

    this.enableMarketplaceV3_2();

    const screenSize = this.responsiveService.getScreenWidth();
    if (screenSize === 'xl') {
      this.itemsPerSlide = 4;
      this.showNavigationArrows = true;
      this.tooltipVisible = true;
      this.arrowsOutside = true;
    } else if (screenSize === 'lg') {
      this.itemsPerSlide = 3;
      this.tooltipVisible = true;
      this.showNavigationArrows = true;
      this.arrowsOutside = true;
    } else if (screenSize === 'md') {
      this.itemsPerSlide = 3;
      this.tooltipVisible = true;
      this.showNavigationArrows = false;
    } else if (screenSize === 'sm') {
      this.itemsPerSlide = 2;
      this.tooltipVisible = false;
      this.showNavigationArrows = false;
    } else {
      this.itemsPerSlide = 4;
      this.showNavigationArrows = true;
      this.tooltipVisible = true;
      this.arrowsOutside = true;
    }
  }

  public isStockDistributionEnabled(): void {
    this._getFeatureAttributeUseCase
      .execute(FEATURE_FLAGS.STOCK_DISTRIBUTION)
      .subscribe((attribute) => {
        this.shouldShowStockDistribution = featureAttributeAssign(attribute, user.id);
      });
  }

  private enableMarketplaceV3_2(): void {
    this._getFeatureAttributeUseCase.execute(FEATURE_FLAGS.WEB_MARKETPLACE_V3_2).subscribe({
      next: (attribute) => {
        this.shouldShowMarketplaceV3_2 =
          featureAttributeAssign(attribute, user.id, country.code) &&
          user.features.includes(FEATURE_FLAGS.MARKETPLACE_V3_2_USER_FEATURE);
      },
    });
  }

  setupInputs(): void {
    const {
      categoryName,
      sorting,
      title,
      randomize,
      featuredGroupId,
      categoryId,
      lockedOnly,
      upSellableVariantId,
      merchantLockedOnly,
    } = this.carouselData || {};
    if (title === LOCKED_PRODUCTS_CAROUSEL_NAME) {
      this.title = title;
      this.merchantLockedOnly = merchantLockedOnly!;
      this.sorting = sorting!;
      this.getProducts();
    } else if (title === LOCKED_PRODUCTS_CATEGORY) {
      this.title = title;
      this.sorting = sorting!;
      this.lockedOnly = lockedOnly!;
      this.getProducts();
    } else if (title === ALL_SALE_PRODUCTS_CAROUSEL) {
      this.title = title;
      this.sorting = sorting!;
      this.onlyGroupsOnSale = true;
      this.getProducts();
    } else if (title === DISCOUNTED_PRODUCTS_CAROUSEL_TITLE) {
      this.title = title;
      this.sorting = sorting!;
      this.onlyDiscountedAsSecondInPlacement = true;
      this.getProducts();
    } else if (title === UPSELLABLE_PRODUCTS_CATEGORY) {
      this.title = title;
      this.sorting = sorting!;
      this.upSellableVariant = upSellableVariantId!;
      this.getProducts();
    } else if (categoryName === LOCKED_TESTABLE_PRODUCTS_CATEGORY) {
      this.title = title;
      this.sorting = sorting!;
      this.getLockedTestableProducts();
    } else {
      if (categoryId) {
        this.categoryId = categoryId;
        this.sorting = sorting!;
        this.title = title;
        this.randomize = randomize!;
        this.featuredGroupId = featuredGroupId!;
        this.getProducts();
      } else {
        let key = '';
        this._getCommercialCategoriesUseCase
          .execute(country.code)
          .pipe(take(1))
          .subscribe({
            next: (tree) => {
              key = tree.root.children.filter(
                (category) =>
                  category.value.name.arabicName === categoryName ||
                  category.value.name.englishName === categoryName,
              )[0]?.key;
              this.categoryId = key;
              this.sorting = sorting!;
              this.randomize = randomize!;
              this.featuredGroupId = featuredGroupId!;
              this.title = title;
              this.getProducts();
            },
          });
      }
    }
  }

  getProducts(): void {
    if (this.randomize) {
      this.pageNumber = Math.ceil(Math.random() * 11) + 1;
    }
    if (this.featuredGroupId) {
      this.getFeaturedGroupProducts();
    } else if (this.upSellableVariant) {
      this.getUpSellableVariants();
    } else {
      this.getCategoryProducts();
    }
  }

  getCategoryProducts(): void {
    this.products = [];
    const query: { [attribute: string]: any } = {
      pageSize: 10,
      page: this.pageNumber,
      sortBy: this.sorting,
      countable: false,
      lockedOnly: this.lockedOnly,
      onlyGroupsOnSale: this.onlyGroupsOnSale,
      merchantLockedOnly: this.merchantLockedOnly,
      ...(this.discountedAsSecondProductFlag
        ? { onlyDiscountedAsSecondInPlacement: this.onlyDiscountedAsSecondInPlacement }
        : {}),
    };
    query.commercialCategoryId = this.categoryId;
    this.productService
      .getProductsForCategory(query)
      .pipe(
        finalize(() => {
          this.loading = false;
        }),
      )
      .subscribe({
        next: (res: any) => {
          this.variantGroups = res.results;
          this._logMixpanelEventUseCase.execute({
            eventName: 'products_carousel_loaded',
            payload: {
              ...this.carouselData,
              ...query,
              results_count: this.variantGroups.length,
              is_featured_category: true,
              is_commercial_category: false,
            },
          });
        },
        error: () => {
          this.variantGroups = [];
        },
      });
  }

  getUpSellableVariants(): void {
    this.products = [];
    this._getUpsellableProductsUseCase
      .execute(this.upSellableVariant)
      .pipe(
        finalize(() => {
          this.loading = false;
          this._logMixpanelEventUseCase.execute({
            eventName: 'upsellable_products_carousel_loaded',
            payload: {
              ...this.carouselData,
              results_count: this.products.length,
            },
          });
        }),
      )
      .subscribe({
        next: (res: any) => {
          this.products = res.data;
        },
        error: () => {
          this.products = [];
        },
      });
  }

  getFeaturedGroupProducts(): void {
    this.variantGroups = [];
    this.productService.getFeaturedProductsGroup(this.featuredGroupId, country.code).subscribe({
      next: (res) => {
        this.products = res.data.products;
        this.loading = false;
        this.products.forEach((product) => {
          product.isFeaturedProduct = true;
        });
        this._logMixpanelEventUseCase.execute({
          eventName: 'products_carousel_loaded',
          payload: {
            ...this.carouselData,
            ...res.data,
            ...res.msg,
            results_count: this.variantGroups.length,
            is_featured_category: true,
            is_commercial_category: false,
          },
        });
      },
      error: () => {
        this.products = [];
      },
    });
  }

  public getLockedTestableProducts(): void {
    const params: LockedTestableProductFilterModel = {
      page: 1,
      pageSize: 25,
    };
    LockedTestableProductsUseCaseResolver.LockedTestableProductsList(params)
      .then((res) => {
        this.products = res.data;
        this.loading = false;
      })
      .catch(() => {
        this.products = [];
      });
  }

  goToLink(): void {
    if (this.featuredGroupId) {
      this.goToFeaturedGroup();
    } else {
      this.goToCategory();
    }
  }

  goToCategory(): void {
    const queryString = {
      currentPage: 1,
      items: 12,
      sorting: this.sorting,
      lockedOnly: this.lockedOnly,
      onlyGroupsOnSale: this.onlyGroupsOnSale,
      ...(this.shouldShowStockDistribution ? { merchantLockedOnly: this.merchantLockedOnly } : {}),
      ...(this.discountedAsSecondProductFlag
        ? { onlyDiscountedAsSecondInPlacement: this.onlyDiscountedAsSecondInPlacement }
        : {}),
    };
    const id = this.categoryId || '';
    this.router.navigate(['/', 'products', 'category', id], { queryParams: queryString });
  }

  goToFeaturedGroup(): void {
    const queryString = {
      q: '',
      featureGroupId: this.featuredGroupId,
      featureGroupTitle: this._translateService.instant(this.title),
      currentPage: 1,
      items: 12,
      sorting: this.sorting,
    };
    this.router.navigate(['/', 'products', 'category'], { queryParams: queryString });
  }
}
