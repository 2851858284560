import { inject, Injectable } from '@angular/core';
import { LogMixpanelEventUseCase } from 'src/app/core/usecases/analytics/log-mixpanel-event.usecase';
import { BasePresenter } from 'src/app/presentation/base/base.presenter';
import { SiteTranslateService } from 'src/app/presentation/shared/services/translate.service';
import { TieringWidgetSideEffect } from './tiering-widget.sideEffects';
import { TieringWidgetViewEvents } from './tiering-widget.viewEvents';
import { TieringWidgetViewState } from './tiering-widget.viewState';

@Injectable()
export class TieringWidgetPresenter extends BasePresenter<
  TieringWidgetViewState,
  TieringWidgetViewEvents,
  TieringWidgetSideEffect
> {
  private _logMixpanelEventUseCase = inject(LogMixpanelEventUseCase);

  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(private _siteTranslateService: SiteTranslateService) {
    super();
  }

  protected defaultViewState(): TieringWidgetViewState {
    return {
      isRTL: true,
      challengeGIFOptions: { path: '/assets/animations/challenges-ar.json' },
    };
  }

  protected onViewEvent(event: TieringWidgetViewEvents): void {
    switch (event.type) {
      case 'Init': {
        this.updateViewState({
          ...this.viewState,
          isRTL: this._siteTranslateService.currentDir === 'rtl',
          challengeGIFOptions: {
            path: `/assets/animations/challenges-${this._siteTranslateService.getCurrentLanguage()}.json`,
          },
        });
        this._siteTranslateService.onLanguageChange().subscribe((lang) => {
          this.updateViewState({
            ...this.viewState,
            isRTL: this._siteTranslateService.currentDir === 'rtl',
            challengeGIFOptions: {
              path: `/assets/animations/challenges-${lang}.json`,
            },
          });
        });

        break;
      }
      case 'ClickGoToDynamicIncentives': {
        this._logMixpanelEventUseCase.execute({ eventName: 'tiering_go_to_incentives_clicked' });
        this.emitSideEffect({
          type: 'NavigateToDynamicIncentives',
        });
        break;
      }
      case 'ClickGoToLoyaltyProgram': {
        this._logMixpanelEventUseCase.execute({ eventName: 'tiering_go_to_loyalty_clicked' });
        this.emitSideEffect({
          type: 'NavigateToLoyaltyProgram',
        });
        break;
      }
    }
  }
}
