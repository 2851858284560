<div class="tiering-container">
  <div
    class="tiering-container__right"
    [ngClass]="viewState.isRTL ? 'left-border' : 'right-border'"
  >
    <app-tiering-progress-bar></app-tiering-progress-bar>
    <button class="tiering-container__right__button" (click)="clickGoToLoyaltyProgram()">
      {{ 'TIERING_SYSTEM_LANDING.TIERS_BENEFITS' | translate }}
    </button>
  </div>
  <div class="tiering-container__left">
    <div class="tiering-container__left__header">
      <p class="body1--bold title">
        {{ 'TIERING_SYSTEM_LANDING.OTHER_CHALLENGES' | translate }}
      </p>
      <button
        class="tiering-container__left__button body2--medium"
        (click)="clickGoToDynamicIncentives()"
      >
        <img class="table-img" src="{{ assetsPath + 'run.svg' }}" />
        {{ 'TIERING_SYSTEM_LANDING.ALL_CHALLENGES' | translate }}
      </button>
    </div>
    <ng-lottie [options]="viewState.challengeGIFOptions" height="fit-content"></ng-lottie>
  </div>
</div>
